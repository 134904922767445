import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dayOffList: [],
    leaveList: [],
    pendingLeave: [],
    singleUserData: {}
}

const leaveSlice = createSlice({
    name: 'leave',
    initialState,
    reducers: {
        setDayOff: (state, action) => {
            state.dayOffList = action.payload;
        },
        addDayOff: (state, action) => {
            state.dayOffList.unshift(action.payload);
        },
        setLeaveList: (state, action) => {
            state.leaveList = action.payload;
        },
        addLeave: (state, action) => {
            state.leaveList.unshift(action.payload);
        },
        setPendingRequests: (state, action) => {
            state.pendingLeave = action.payload;
        },
        setUserLeaveData: (state, action) => {
            state.singleUserData = action.payload;
        }
    }
});

export const { setDayOff, addDayOff, setLeaveList, addLeave, setPendingRequests, setUserLeaveData } = leaveSlice.actions;

export default leaveSlice.reducer;