import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    invoiceDetail: {},
    modalOpen: false,
    filterValues: [],
    searchQuery: '',
    order: ''
}

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setInvoice: (state, action) => {
            state.data = action.payload;
        },
        setInvoiceData: (state, action) => {
            state.invoiceDetail = action.payload;
        },
        setFilter: (state, action) => {
            state.filterValues = action.payload;
        },
        setModal: (state, action) => {
            state.modalOpen = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        },
    }
});

export const { setInvoice, setInvoiceData, setModal, setSearchQuery, setOrder, setFilter } = invoiceSlice.actions;

export default invoiceSlice.reducer;