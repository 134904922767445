import React, { useState, useEffect } from 'react'
import "../../client/scss/dashboardContent.scss";
import { Skeleton, Icon, Tooltip, IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom";
import "../../client/scss/modal.scss";
import "../../client/scss/pendingRequest.scss";
import api from '../../config/axios';
import { useDispatch, useSelector } from "react-redux";
import { setPendingRequests } from "../../reducers/leaveSlice";
import ShowToast from "../../pages/admin/Toast";

const PendingRequests = () => {
    const [load, setLoad] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pendingLeave = useSelector((state) => state.leave.pendingLeave);
    const profile = useSelector((state) => state.auth.data);

    async function allLeaveRequests() {

        try {
            const response = await api.get(`/api/v1/offday_requests/pending_request`);
            if (response.data.status == 'success') {
                dispatch(setPendingRequests(response.data.data.items));
            }
        }
        catch (error) {
            ShowToast(error.response.statusText, "error");
        }
        finally {
            setLoad(false)
        }
    }

    useEffect(() => {
        allLeaveRequests();
    }, [])

    return (
        <>
            <ol className="list-group card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className='title'>Leave Request</div>
                    <button className="btn" onClick={() => navigate('/users/leave')}>view all</button>
                </div>
                <div className="card-body p-0">
                    {!load && (
                        pendingLeave.length > 0 ? (
                            pendingLeave.map((item, i) => (
                                <li key={i} className="list-group-item d-flex justify-content-between align-items-center">
                                    <div className="me-auto text-start">
                                        <div className="name">{item.profile.user.full_name}</div>
                                        <div className="type">{item.profile.type}</div>
                                    </div>
                                    <Tooltip sx={{ marginRight: '4px' }} type="button" className="updateBtn btn" onClick={() => navigate(`/users/leave/${item.id}`)} title="view detail">
                                        <IconButton>
                                            <Icon fontSize="small">
                                                arrow_outward
                                            </Icon>
                                        </IconButton>
                                    </Tooltip>
                                </li>
                            ))
                        ) :
                            <li className="list-group-item">
                                <div className="me-auto">
                                    <div className="no-data text-align-center">not data axist</div>
                                </div>
                            </li>
                    )}
                    {load && <>
                        {Array.from({ length: 5 }, (_, i) => (
                            <li key={i} className="list-group-item">
                                <div>
                                    <div className="sk-name"><Skeleton animation="wave" /></div>
                                    <Skeleton animation="wave" />
                                </div>
                            </li>
                        ))}
                    </>}
                </div >
            </ol >
        </>
    )
}

export default PendingRequests;