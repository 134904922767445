import React, { useEffect, useState, useContext, useRef } from 'react';
import Link from '@mui/material/Link'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom"
import { SidebarMenuContext } from "../../scripts/utils/SidebarMenuContext";
import { useDispatch, useSelector } from 'react-redux';
import { setMenu } from '../../reducers/authSlice';

const theme = createTheme({
    palette: {
        background: {
            transparent: 'transparent',
        },
        text: {
            primary: '#03A9F4',
            secondary: '#fff',
        },
        active: {
            background: "#03A9F4",
            '&:hover': {
                background: "#03A9F4",
            }
        },
        action: {
            active: '#03A9F4',
        }
    },
});

export default function MenuItemList(props) {
    const dispatch = useDispatch();
    const sidebarContext = useContext(SidebarMenuContext);
    const profile = useSelector((state) => state.auth.data);
    const menus = props.menuSideBar;
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const sidebarRef = useRef(null);
    const handleListItemClick = (index) => {
        if (selectedIndex === index) {
            setSelectedIndex(null);
        } else {
            setSelectedIndex(index);
        }
    };

    const updateFormHeight = () => {
        const windowHeight = window.innerHeight;

        if (windowHeight < 700 && profile.menu?.length > 5) {
            // Limit to the first 4 items and add the "Other" menu item
            const visibleItems = profile.menu.slice(0, 5);
            const remainingItems = profile.menu.slice(5);

            const otherMenu = {
                id: "other",
                displayName: "Other",
                icon: "more_horiz",
                hasSubMenu: true,
                nestedEndpoints: remainingItems.map(item => ({
                    id: item.id,
                    displayName: item.displayName,
                    icon: item.icon,
                    endPoint: item.endPoint
                }))
            };
            dispatch(setMenu([...visibleItems, otherMenu]));
        }
        if (windowHeight >= 700 && windowHeight <= 820 && profile.menu?.length > 6) {
            // Limit to the first 5 items and add the "Other" menu item
            const visibleItems = profile.menu.slice(0, 6);
            const remainingItems = profile.menu.slice(6);

            const otherMenu = {
                id: "other",
                displayName: "Other",
                icon: "more_horiz",
                hasSubMenu: true,
                nestedEndpoints: remainingItems.map(item => ({
                    id: item.id,
                    displayName: item.displayName,
                    icon: item.icon,
                    endPoint: item.endPoint
                }))
            };
            dispatch(setMenu([...visibleItems, otherMenu]));
        }
        if (windowHeight > 1000 && profile.menu?.length > 0) {
            dispatch(setMenu(profile.menu));
        }
    }

    useEffect(() => {
        updateFormHeight();

        window.addEventListener("resize", updateFormHeight);
        return () => {
            window.removeEventListener("resize", updateFormHeight);
        };
    }, [menus?.length]);

    const handleNavigation = (endpoint) => {
        // Use the navigate function to navigate to the selected endpoint
        if (endpoint.endPoint) {
            navigate(endpoint.endPoint);
            sidebarContext.setCollapsedMob(false)

        }
    };

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div className="sidebar-menu" ref={sidebarRef}>
            <ThemeProvider theme={theme}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.transparent' }} component="nav">
                    <>
                        {menus?.map((menuItem, index) => {
                            return (
                                <div className={menuItem.hasSubMenu ? "submenu-item" : ""} key={index}>
                                    <Link onClick={(e) => { e.preventDefault(); handleNavigation(menuItem) }}>
                                        <ListItemButton className='menu-button' onClick={() => handleListItemClick(index)}
                                            selected={selectedIndex === index}>
                                            <ListItemIcon sx={{ color: 'text.secondary' }} className="menu-icon">
                                                <Icon sx={{ fontSize: 24 }}>{menuItem.icon}</Icon>
                                            </ListItemIcon>
                                            <ListItemText sx={{ color: 'text.secondary' }} className="menu-text">{menuItem.displayName}</ListItemText>
                                            {sidebarContext.collapsed && menuItem.hasSubMenu && (
                                                selectedIndex === index ? <ExpandLess /> : <ExpandMore />)
                                            }
                                        </ListItemButton>
                                    </Link>
                                    {sidebarContext.collapsed &&
                                        menuItem.hasSubMenu &&
                                        <Collapse in={selectedIndex == index} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {menuItem.nestedEndpoints.map((nestedItem, index) => {
                                                    return (
                                                        <Link key={index} onClick={(e) => { e.preventDefault(); handleNavigation(nestedItem) }}>
                                                            <ListItemButton className='menu-button' sx={{ pl: 4, color: 'text.secondary' }}>

                                                                <ListItemIcon sx={{ color: 'text.secondary' }} className="menu-icon">
                                                                    <Icon sx={{ fontSize: 24 }}>{nestedItem.icon}</Icon>
                                                                </ListItemIcon>
                                                                <ListItemText className="menu-text" sx={{ color: 'text.secondary' }}> {nestedItem.displayName}</ListItemText>
                                                            </ListItemButton>
                                                        </Link>
                                                    )
                                                })}
                                            </List>
                                        </Collapse>
                                    }
                                    {!sidebarContext.collapsed &&
                                        menuItem.hasSubMenu &&
                                        <div className="submenu">
                                            <ul>
                                                {menuItem.nestedEndpoints.map((nestedItem, index) => {
                                                    return (
                                                        <li key={index} onClick={(e) => { e.preventDefault(); handleNavigation(nestedItem) }}>
                                                            <span className="menu-icon">
                                                                <Icon sx={{ fontSize: 15 }}>{nestedItem.icon}</Icon>
                                                            </span>
                                                            <span className="menu-text"> {nestedItem.displayName}</span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    }
                                </div>
                            );
                        })}
                    </>
                </List>
            </ThemeProvider>
        </div>
    );
}