import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import DashboardMetrics from './DashboardMetrics'
import "../../client/scss/dashboardContent.scss";
import { Icon } from '@mui/material';
import AppointmentList from "./AppointmentList";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from 'react-error-boundary';
import FallBack from './FallBack';
import AccessControlHOC from './Hoc';
import PendingRequests from '../Leave/PendingRequests';
import Modal from "../../components/Modal";
import "../../client/scss/modal.scss";
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

const DashboardContent = () => {
	const [modal, setModal] = useState();

	const modalOpen = () => {
		setModal(true);
	}
	const modalClose = () => {
		setModal(false);
	};

	return (
		<div className='item-container'>
			<ToastContainer />
			<ErrorBoundary fallback={<FallBack />}>

				<DashboardMetrics />
			</ErrorBoundary>
			<div className='dashboard-grid'>
				<Row>
					<Col xs={12} lg={8} className='component-body today-appointment mb-4'>
						<ErrorBoundary fallback={<FallBack />}>
							<AccessControlHOC
								component={AppointmentList}
								ability={[{ action: 'manage', subject: 'all' }, { action: 'manage', subject: 'Appointment' }]}
							/>
						</ErrorBoundary>

					</Col>
					<Col xs={12} lg={4} className='component-body leave-list'>
						<ErrorBoundary fallback={<FallBack />}>
							<AccessControlHOC
								component={PendingRequests}
								ability={[{ action: 'manage', subject: 'all' }]}
							/>
						</ErrorBoundary>
					</Col>
				</Row>
				{modal && (
					<Modal>
						<div className="modal-content-sm">
							<div id="modal">
								<div className="header-modal">In progress...</div>
								<button className="close-modal tooltip" onClick={modalClose}>
									<span className="tooltiptext">Discard or Close</span>
									<Icon>close_icon</Icon>
								</button>
							</div>
						</div>
					</Modal>
				)}
			</div >
		</div >
	)
}

export default DashboardContent;