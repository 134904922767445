import React, { useContext, useEffect } from 'react'
import adminLogo from "../../client/images/logo-white.svg";
import MenuItemList from './MenuItemList';
import { SidebarMenuContext } from '../../scripts/utils/SidebarMenuContext';
import { Icon } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { loginSuccess } from '../../reducers/authSlice';
import api from "../../config/axios";
import ShowToast from "../../pages/admin/Toast";
import JWTService from '../../scripts/services/JWTService';

const Sidebar = () => {

    const profile = useSelector((state) => state.auth.data);
    const firstNameShort = profile.user?.first_name[0];
    const lastNameShort = profile.user?.last_name[0];
    const firstName = profile.user?.first_name;
    const lastName = profile.user?.last_name;
    const menu = useSelector((state) => state.auth.menu);
    const sidebarContext = useContext(SidebarMenuContext);

    let bodyTag = document.body;

    if (sidebarContext.collapsedmob) {
        bodyTag.style.overflowY = 'hidden';
    } else {
        bodyTag.style.overflowY = '';
    }

    return (
        <div className={`sidebar ${sidebarContext.collapsedmob ? "open" : ""} ${sidebarContext.collapsed ? "" : "sidebar-collapsed"}`}>
            <div className='sidebar-background' style={{ backgroundColor: '#00e6ff' }}>
                <div className='sidebar-content'>
                    <div className="sidebar-logo">
                        <div className="circle">{firstNameShort}{lastNameShort}</div>
                        <a className="organisation" href="/" target="_blank">
                            <img src={`${adminLogo}`} alt="logo" className="admin-logo" />
                            <h4 className='mb-0 ms-3'>
                                {profile.organization?.name}
                            </h4>
                        </a>
                        {sidebarContext.collapsedmob && <button className="close-menu" onClick={sidebarContext.handleMenuToggle}>
                            <Icon>close_icon</Icon>
                        </button>}
                    </div>

                    {sidebarContext.collapsed && <div className='sidebar-admin'>
                        <a href="/users/profile">
                            <Icon>person</Icon>
                            <p className='sidebar-admin-name mb-0 ms-3'>{firstName} {lastName} ({profile?.active_profile?.type})</p>
                        </a>
                    </div>
                    }
                    <MenuItemList menuSideBar={menu} />
                </div>
            </div>
        </div>
    )
}

export default Sidebar