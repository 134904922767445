import React from 'react';
import api from "../config/axios";
import { Box } from "@mui/material";
import "../client/scss/PaymentWarning.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShowToast from "../pages/admin/Toast";

const PaymentWarning = () => {
	const profile = useSelector((state) => state.auth.data);
	const navigate = useNavigate();

	const handleCheckout = async () => {
		try {
			const response = await api.post('/api/v1/payment/create_checkout_session');
			const { url } = response.data.data;

			window.location.href = url;
		} catch (error) {
			ShowToast('Error creating Checkout Session:', error);
		}
	};

	const goToPlan = async () => {
		navigate(`/billing`);
	};

	return (
		<>
			{profile.plan == 'Trial' && (<Box className="payment-warning" component="section" sx={{ p: 1, border: '1px dashed grey' }}>
				<div className='d-flex justify-content-between align-items-center'><div className="trial-text">Your trial is expiring in <span className="number">{profile.trial_duration} Days</span></div><div><button className="action-btn mx-2" onClick={goToPlan}>Choose your plan</button><button className="query-btn">Talk To Us</button></div></div>
			</Box>
			)}
		</>);
};

export default PaymentWarning;
