import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    openModal: false,
    isChecked: false,
    searchQuery: '',
    order: ''
}

const pharmacistSlice = createSlice({
    name: 'pharmacist',
    initialState,
    reducers: {
        setPharmacist: (state, action) => {
            state.data = action.payload;
        },
        addPharmacist: (state, action) => {
            state.data.push(action.payload)
        },
        setModal: (state, action) => {
            state.openModal = action.payload;
        },
        setIsChecked: (state, action) => {
            state.isChecked = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        }
    },
});

export const { setPharmacist, addPharmacist, setModal, setIsChecked, setSearchQuery, setOrder } = pharmacistSlice.actions;

export default pharmacistSlice.reducer;