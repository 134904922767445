import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    shiftList: [],
    modal: false,
    updateBtn: false,
    timeZone: [],
    time: {},
    secretKey: {},
    cash: {},
    features: ''
}

const organisationSettingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {

        addShift: (state, action) => {
            state.shiftList.push(action.payload)
        },
        setShift: (state, action) => {
            state.shiftList = action.payload;
        },
        setShiftModal: (state, action) => {
            state.modal = action.payload;
        },
        setUpdateBtn: (state, action) => {
            state.updateBtn = action.payload;
        },
        getTimeZone: (state, action) => {
            state.time = action.payload;
        },
        getSecretKey: (state, action) => {
            state.secretKey = action.payload;
        },
        getCashData: (state, action) => {
            state.cash = action.payload;
        },
        setFeatures: (state, action) => {
            state.features = action.payload;
        }
    },
});

export const { addShift, setShiftModal, setUpdateBtn, setShift, getTimeZone, getSecretKey, getCashData, setFeatures } = organisationSettingSlice.actions;

export default organisationSettingSlice.reducer;