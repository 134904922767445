import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   department: "",
   departmentId: "",
   designation: "",
   allowAddDep: false,
   allowAddDesign: false
}

const dyanmicFields = createSlice({
    name: 'dynamicFormSlice',
    initialState,
    reducers: {
      setDepartment: (state, action) => {
         state.department = action.payload;
      },
      setDepartmentId: (state, action) => {
         state.departmentId = action.payload;
      },
      setDesignation: (state, action) => {
         state.designation = action.payload;
      },
      resetDepartmentId: (state, action) => {
         state.departmentId = action.payload;
      },
      setAllowAddDepartment: (state, action) => {
         state.allowAddDep = action.payload;
      },
      setAllowAddDesign: (state, action) => {
         state.allowAddDesign = action.payload;
      },
      resetForm: (state, action) => {
         state.department = "";
         state.designation = "";
      }
    },
});

export const { 
   setDepartment,
   setDepartmentId, 
   resetDepartmentId,
   setDesignation,
   resetForm,
   setAllowAddDepartment,
   setAllowAddDesign
 } = dyanmicFields.actions;

export default dyanmicFields.reducer;