import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/admin/Dashboard";
import { SidebarProvider } from "./scripts/utils/SidebarMenuContext";
import SignUp from "./pages/admin/SignUp";
import SignIn from "./pages/admin/SignIn";
import ProtectedRoute from "./scripts/utils/ProtectedRoute";
import PasswordProtectionMiddleware from "./middleware/PasswordProtectionMiddleware";
import EmailVerification from "./pages/admin/EmailVerification";
import NotFound from "./pages/common/NotFound";
import UsersRoutes from "./pages/admin/UsersRoutes";
import ForgotPassword from "./pages/admin/ForgotPassword";
import UpdatePassword from "./pages/admin/UpdatePassword";
import { useSelector, useDispatch } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import FallBack from "./components/admin/FallBack";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import api from "./config/axios";
import ShowToast from "./pages/admin/Toast";
import JWTService from "./scripts/services/JWTService";
import { loginSuccess, setMenu } from "./reducers/authSlice";
import AccessControlHOC from "./components/admin/Hoc";
import { getSavedHeader } from "./config/axios";
const Department = React.lazy(() => import("./pages/admin/DepartmentBoundary"));
const Designation = React.lazy(() => import("./pages/admin/DesignationBoundary"));
const Services = React.lazy(() => import("./pages/admin/ServicesBoundary"));
const Reporting = React.lazy(() => import("./pages/admin/ReportingBoundary"));
const OrganizationSettingsPage = React.lazy(() => import("./pages/admin/OrganizationSettingsPage"));
const DutyRosterBoundary = React.lazy(() => import("./pages/admin/DutyRosterBoundary"));
const Plan = React.lazy(() => import("./pages/admin/PlanBoundary"));
const Invoices = React.lazy(() => import("./pages/admin/InvoicesBoundary"));
const InvoiceDetailViewBoundary = React.lazy(() => import("./pages/admin/InvoiceDetailViewBoundary"));
const Pharmacist = React.lazy(() => import("./pages/admin/PharmacistBoundary"));
const AddToCart = React.lazy(() => import("./pages/admin/AddToCart"));

function LoadingIndicator() {
    return <div className="form-loader"></div>;
}

function App() {
    const loading = useSelector((state) => state.loader.loading);
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.data);

    async function fetchSession() {
        try {
            const response = await api.get("/api/v1/session_info");
            dispatch(loginSuccess(response.data.data));
            dispatch(setMenu(response.data.data.menu));
        } catch (error) {
            if (error.response.status == 401) {
                JWTService.removeToken();
                window.location.href = "/login";
            }
            ShowToast(error.response.data.error, "error");
        }
    }

    useEffect(() => {
        const isAuthenticated = getSavedHeader("client") ? true : false;
        if (isAuthenticated) {
            if (Object.entries(profile).length === 0) {
                fetchSession();
            }
        }
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <PasswordProtectionMiddleware>
                    <SidebarProvider>
                        <Routes>
                            <Route
                                path="/dashboard"
                                element={<ProtectedRoute component={<Dashboard />} />}
                            />
                            <Route
                                path="/departments"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute
                                            component={
                                                <AccessControlHOC
                                                    component={Department}
                                                    ability={[{ action: "manage", subject: "all" }]}
                                                    pageControl={true}
                                                />
                                            }
                                        />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/designation"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute
                                            component={
                                                <AccessControlHOC
                                                    component={Designation}
                                                    ability={[
                                                        { action: "manage", subject: "all" },
                                                        { action: "read", subject: "Designation" },
                                                    ]}
                                                    pageControl={true}
                                                />
                                            }
                                        />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/pharmacist"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute
                                            component={
                                                <AccessControlHOC
                                                    component={Pharmacist}
                                                    ability={[
                                                        { action: "manage", subject: "all" }
                                                    ]}
                                                    pageControl={true}
                                                />
                                            }
                                        />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/services"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute
                                            component={
                                                <AccessControlHOC
                                                    component={Services}
                                                    ability={[
                                                        { action: "manage", subject: "all" },
                                                        { action: "read", subject: "Service" },
                                                    ]}
                                                    pageControl={true}
                                                />
                                            }
                                        />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/reporting"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute
                                            component={
                                                <AccessControlHOC
                                                    component={Reporting}
                                                    ability={[{ action: "manage", subject: "all" }]}
                                                    pageControl={true}
                                                />
                                            }
                                        />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/organisation/settings"
                                element={
                                    <Suspense>
                                        <ProtectedRoute
                                            component={
                                                <AccessControlHOC
                                                    component={OrganizationSettingsPage}
                                                    ability={[{ action: "manage", subject: "all" }]}
                                                    pageControl={true}
                                                />
                                            }
                                        />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/duty_roster"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute component={<DutyRosterBoundary />} />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/billing"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute component={<Plan />} />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/invoices"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute component={<Invoices />} />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/invoice/:id"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute component={<InvoiceDetailViewBoundary />} />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/add_to_cart"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ProtectedRoute
                                            component={
                                                <AccessControlHOC
                                                    component={AddToCart}
                                                    ability={[{ action: "manage", subject: "Cart" }, { action: "manage", subject: "all" }]}
                                                    pageControl={true}
                                                />
                                            }
                                        />
                                    </Suspense>
                                }
                            />
                            <Route path="/users/*" element={<UsersRoutes />} />
                            <Route path="/" element={<SignIn />} />
                            <Route path="/login" element={<SignIn />} />
                            <Route path="/register" element={<SignUp />} />
                            <Route path="/forgot_password" element={<ForgotPassword />} />
                            <Route path="/update_password" element={<UpdatePassword />} />
                            <Route
                                path="/verify-email"
                                element={
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <ErrorBoundary fallback={<FallBack />}>
                                            <EmailVerification />
                                        </ErrorBoundary>
                                    </Suspense>
                                }
                            />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </SidebarProvider>
                </PasswordProtectionMiddleware>
            </BrowserRouter>
            {loading && <div className="form-loader"></div>}
        </div>
    );
}

export default App;
