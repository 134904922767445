import React, { useContext } from 'react';
import { IconButton, Grid, Avatar, Icon } from '@mui/material';
import "../../client/scss/_topbar.scss";
import api from "../../config/axios";
import { SidebarMenuContext } from '../../scripts/utils/SidebarMenuContext';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import JWTService from '../../scripts/services/JWTService';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowToast from "../../pages/admin/Toast";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../reducers/userDetails";
import AccessControlHOC from './Hoc';

const Topbar = () => {

    const sidebarContext = useContext(SidebarMenuContext);
    const profile = useSelector((state) => state.auth.data);
    const firstName = profile.active_profile?.first_name;
    const lastName = profile.active_profile?.last_name;
    const email = profile.active_profile?.email;
    const profileType = profile.active_profile?.type;
    const profiles = profile.profiles;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const profileSwitch = (userType) => {

        api.post("/api/v1/profile_switching",
            {
                "profiles_type": userType
            }
        )
            .then(res => {
                if (res.data.status === "success") {
                    gotToDashBoard();
                    window.location.reload();
                }
            })
            .catch(error => {
                ShowToast(error.response.data.error.message, "error");
            });
    }

    const Logout = () => {

        api.delete("api/v1/auth/sign_out")
            .then(res => {
                if (res.status === 200) {
                    JWTService.removeToken();
                    window.location.href = '/login';
                }
            })
            .catch(err => {

            });
    }

    const gotToDashBoard = () => {
        navigate(`/dashboard`);
    };

    const gotToSettings = () => {
        navigate(`/organisation/settings`);
    };

    const handleUpdate = () => {
        dispatch(setUserData([]));
        navigate(`/users/profile`);
    };

    const profilesList = profiles?.filter((item) => item != profileType);

    return (
        <>
            <div className='topbar'>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div className='topbar-group group-one'>
                            <IconButton variant="contained" color="success" onClick={sidebarContext.handleMenuToggle}>
                                <Icon>menu</Icon>
                            </IconButton>
                        </div>
                    </Grid>
                    <ToastContainer />
                    <Grid item xs={6}>
                        <div className='topbar-group group-two'>
                            <AccessControlHOC
                                component={() =>
                                    <div className='topbar-configs-group'>
                                        <MDBDropdown>
                                            <MDBDropdownToggle color="light" onClick={gotToSettings}>
                                                <Icon>settings</Icon>
                                            </MDBDropdownToggle>
                                        </MDBDropdown>

                                    </div>
                                }
                                ability={[{ action: 'manage', subject: 'all' }]}
                            />
                            <div className='topbar-notification-group'>
                                <MDBDropdown>
                                    <MDBDropdownToggle color="light">
                                        <Icon>notifications</Icon>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem link>You have a new message</MDBDropdownItem>
                                        <MDBDropdownItem link>Registered Successfully</MDBDropdownItem>
                                        <MDBDropdownItem link>Something else here</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>

                            </div>
                            <div className='topbar-user-group'>
                                <MDBDropdown>
                                    <MDBDropdownToggle color="light">
                                        <Icon>person</Icon>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <div className="profile-item heading">Account</div>
                                        <MDBDropdownItem className="profile-item menu-info">
                                            <div className="profile-item inner-info">
                                                <div className="profile-item image">
                                                    <Avatar className='topbar-admin-avatar' alt="admin-logo" />
                                                </div>
                                                <div className="profile-item basic-info">
                                                    <div>{firstName} {lastName}</div>
                                                    <div>{email} {`(${profileType})`}</div>
                                                </div>
                                            </div>
                                        </MDBDropdownItem>
                                        <MDBDropdownItem link className="profile-item edit-info" onClick={handleUpdate}>
                                            Edit Profile
                                        </MDBDropdownItem>
                                        {profiles?.length > 1 ?
                                            <MDBDropdownItem className="profile-item profile" style={{ fontSize: '13px', fontWeight: '700' }}>Switch Profile</MDBDropdownItem>
                                            : <div></div>
                                        }
                                        {profilesList?.map((item, i) => (
                                            <MDBDropdownItem link key={i} className={`profile-item sub-profile ${item}`} onClick={() => profileSwitch(item)}>
                                                {item}
                                            </MDBDropdownItem>
                                        ))}

                                        <MDBDropdownItem className="profile-item logout" link onClick={Logout}>Logout</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="top-bar login-type">You are currently login as <a>{profileType}</a></div>
        </>
    )
}

export default Topbar